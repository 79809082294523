import React from 'react';                                                                                              
import styled from 'styled-components';
import Helmet from "react-helmet"

const DATA = require('../../redirect-data.json')

const REDIRECT = DATA.reduce((acc, val) => {
    return {
        ...acc,
        [val[0]]: val[1]
    }
}, {})

const NOTE = DATA.reduce((acc, val) => {
    return {
        ...acc,
        [val[0]]: val[2]
    }
}, {})

const OG = DATA.reduce((acc, val) => {
    return {
        ...acc,
        [val[0]]: val[3]
    }
}, {})

function jump(url) {
    window && (window.location.href = url)
}

const RedirectPage = () => {
    const [target, setTarget] = React.useState(null)
    const [q, setQ] = React.useState('')

    const [command, setCommand] = React.useState(null)

    const [og, setOG] = React.useState(null)

    const [quite, setQuite] = React.useState(true)

    const [inputFilter, setInputFilter] = React.useState('')

    React.useEffect(() => {
        try {
            const queryMap = getQueryParams(window.location.search)

            if (Object.keys(queryMap).length === 0) {
                jump('/')
            }

            const c = queryMap['c'], t = queryMap['t'], debug = queryMap['debug']+'' === '1', f = !!queryMap['f']

            if (f) {
                setQuite(true)
            } else {
                setQuite(false)
            }

            if (c) {
                if (c === 'all') {
                    setCommand('all')
                }
            } else if (t) {
                setQ(t)
                const _t = REDIRECT[t]
                if (!_t) {
                    throw 'QQ'
                }

                setTarget(_t)

                if (OG[t]) {
                    setOG(OG[t])
                }

                setTimeout(() => !debug && jump(_t), f ? 10 : 1000)
                
            } else {
                throw 'QQ'
            }
        } catch (err) {
            const queryMap = getQueryParams(window.location.search)

            const c = queryMap['c'], t = queryMap['t'], debug = queryMap['debug']+'' === '1', f = !!queryMap['f']
            setTarget('QQ')

            setTimeout(() => {
                !debug && jump('/')
            }, f ? 10 : 1000)
        }
    }, [])

    return quite ? <div/> : (
        <Wrapper>
            <SEO />

            <a className='action' href='/' target='_blank' style={{cursor:'pointer', flexDirection:'flow', justifyContent:'center', alignItems:'center',textDecoration:'none', color:'black'}}>
                <p  style={{fontSize:'2rem', fontWeight:500, textDecoration:'none', color:'black'}}>短網址</p>
             </a>
            <div className='content'>
                {/*<img src='/logo.png' width='60' style={{maxWidth:200, width:'50%'}} />*/}

                <img src='/Walk.gif' width='80' style={{marginBottom:24}}/>

                {
                    (command === 'all') && (
                        <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>

                            <input style={{width:'80%', height:40, paddingLeft:10}} value={inputFilter} placeholder='輸入過濾字串' onChange={(e)=>{ setInputFilter(e.target.value) }} />

                            {inputFilter === '' && <h4 style={{color:'#282A3A'}}>?t=xxx ... xxx 為短網址 id</h4>}
                            {inputFilter === '' &&<h4 style={{color:'#282A3A',marginTop:-10}}>?f=1 ... 直接跳轉不顯示中介頁</h4>}
                            {inputFilter === '' &&<h4 style={{color:'#282A3A',marginBottom:40, marginTop:-10}}>?debug=1 ... 一切如常只是不跳轉</h4>}


                            <div style={{width:'80%', marginTop:0, marginBottom:40,height:2, backgroundColor:'lightgrey'}} />

                            {

                                Object.keys(REDIRECT)
                                .reduce((acc, val) => {
                                    console.log('...', acc, val, inputFilter)
                                    if (inputFilter === '') {
                                        return [...acc, val]
                                    }
                                    
                                    if (JSON.stringify(REDIRECT[val] || '').toLowerCase().indexOf(inputFilter.toLowerCase()) !== -1) {
                                        return [...acc, val]
                                    }

                                    if (JSON.stringify(NOTE[val] || '').toLowerCase().indexOf(inputFilter.toLowerCase()) !== -1) {
                                        return [...acc, val]
                                    }

                                    if (JSON.stringify(OG[val] || '').toLowerCase().indexOf(inputFilter.toLowerCase()) !== -1) {
                                        return [...acc, val]
                                    }

                                    return acc
                                }, [])
                                .map((k, idx) => {
                                    return (
                                        <div style={{display:'flex', flexDirection:'column', alignItems:'center', marginBottom:50, }} key={idx} >
                                            {NOTE[k] && NOTE[k] !== '' && <h3 style={{color:'#009EFF', marginBottom:-10}}>{NOTE[k]}</h3>}
                                            {/*<h4 style={{color:'#227C70', marginBottom:-10, cursor:'copy'}} onClick={() => window && window.navigator.clipboard.writeText('https://www.sam-huang.info/s/'+k)}>中轉: /s/{k} ⎘</h4>*/}
                                            <h4 style={{color:'#227C70', marginBottom:-10, cursor:'copy',fontWeight:'bold'}} onClick={() => window && window.navigator.clipboard.writeText('https://l.revtel.app/s/'+k)}>中轉: /s/{k} (revtel) ⎘</h4>
                                            {/*<h4 style={{color:'#C58940', marginBottom:-10, cursor:'copy'}} onClick={() => window && window.navigator.clipboard.writeText('https://www.sam-huang.info/j/'+k)}>立即: /j/{k} ⎘</h4>*/}
                                            <h4 style={{color:'#C58940', marginBottom:-10, cursor:'copy',fontWeight:'bold'}} onClick={() => window && window.navigator.clipboard.writeText('https://l.revtel.app/j/'+k)}>立即: /j/{k} (revtel) ⎘</h4>
                                            {splitLineToChunk(REDIRECT[k], c => <p key={c} style={{marginBottom:-5, color:'#8B7E74', textDecoration:'underline', cursor:'pointer'}} onClick={() => window.open(REDIRECT[k], '_blank')}>{c}</p>)}
                                        </div>
                                    )
                                })
                            }
                        </div>
                    )
                }


                {
                    (target && target !== 'QQ') && <h3 style={{padding:15, color:'#344D67'}}>1 秒後跳轉 ! 如無跳轉請點擊下方連結 !</h3>
                }
                {
                    (target && target !== 'QQ') && (function(target) {
                        return (
                            <p style={{marginTop:0, cursor:'pointer', textDecoration:'underline', color:'#8B7E74'}} onClick={()=>window.open(target)}>
                                {splitLineToChunk(target, c => <p key={c} style={{lineHeight:0.5}}>{c}</p>)}
                            </p>
                        )
                    })(target)
                }
                {
                    (target && target !== 'QQ') && <p style={{cursor:'copy', marginTop:24, padding:15, backgroundColor:'#F5EBE0', borderRadius:15}} onClick={() => window && window.navigator.clipboard.writeText(target)}>複製網址</p>
                }

                {
                    (target && target !== 'QQ' && og) && (
                        <div style={{display:'flex', flexDirection:'column', alignItems:'center', width: '70%', maxWidth:350, marginTop:20}}>
                            <div style={{backgroundColor:'lightgrey', width:'80%', height:2}} />
                            <img src={og.image} style={{width:'100%', marginTop:20}} />
                            <p style={{marginTop:20, color: '#3D5656'}} >{og.title}</p>
                        </div>
                    )
                }





                {
                    (target && target === 'QQ') && <h1 style={{padding:15, color:'#D23369'}}>錯誤連結！</h1>
                }
                {
                    (target && target === 'QQ') && <h3 style={{padding:15, color:'#2A3990', marginTop:-10}}>查無 &quot;{q}&quot; 之對應資料</h3>
                }
                 {
                    (target && target === 'QQ') && <p style={{cursor:'copy', marginTop:24, padding:15, backgroundColor:'#F5EBE0', borderRadius:15}} onClick={() => window && window.open('/')}>回首頁</p>
                }

            </div>
            <a className='action' href='/' target='_blank' style={{cursor:'pointer', flexDirection:'flow', justifyContent:'center', alignItems:'center',textDecoration:'none', color:'black'}}>
                <img src='/left-arrow.png' width='36' />
             </a>
        </Wrapper>
    );
}

const SEO = () => {
    const seo = {
        title: '簡易轉址',
        image: 'https://www.sam-huang.info/og.png',
        description: 'shorten url by Sam',
    }
    return (
        <Helmet
     
      title={seo.title}
      meta={[
        {
          name: `description`,
          content: seo.description
        },
        {
          property: `image`,
          content: seo.image
        },
        {
          property: `name`,
          content: seo.title
        },
        {
          property: `og:title`,
          content: seo.title
        },
        {
          property: `og:url`,
          content: 'https://www.sam-huang.info/r/'
        },
        {
          property: `og:site_name`,
          content: 'Sam Huang Info',
        },
        {
          property: `og:image`,
          content: seo.image
        },
        {
          property: `og:description`,
          content: seo.description
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:image`,
          content: seo.image
        },
        {
          name: `twitter:creator`,
          content: 'Sam Huang,黃奕翔'
        },
        {
          name: `twitter:title`,
          content: seo.title
        },
        {
          name: `twitter:description`,
          content: seo.description
        },
        {
          property: 'keywords',
          content: 'Sam Huang, Sam, sailplaneTW, 黃奕翔, 賣飛機的, 忻旅科技, Revtel, RevtelTech, 奕果雲端, Eculture, EcultureTech, React, React Native, Inigma, Tel25, Tel-U, 加密鏈, 軟體開發, 軟體顧問, 新創營運, web3, 區塊鏈',
        },
      ]}
    />
    )
}

function splitLineToChunk(s, func) {
    return s.match(/.{1,30}/g).map(func)

}

function getQueryParams(qs) {
    qs = qs.split('+').join(' ');

    var params = {},
        tokens,
        re = /[?&]?([^=]+)=([^&]*)/g;

    while (tokens = re.exec(qs)) {
        params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
    }

    return params;
}

const Wrapper = styled.div`
    min-height: 98vh;
    

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > .action {
        display: flex;
        flex-direction: row;
        //padding: 15px;
        width:100%;
        height:64px;
        background-color: #EEEEEE;
    }

    & > .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex:1;
        width:100%;
        padding-top:50px;
        padding-bottom:50px;
        //background-color: #FEFCF3;
    }
`

export default RedirectPage;
